// http://localhost:5173/test
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'universal-cookie';

const baseUrl=process.env.REACT_APP_BASE_URL;
const cookies = new Cookies(null, { path: '/' });

const baseQuery = fetchBaseQuery({
  baseUrl: `${baseUrl}`,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    // const token = cookies.get("recruiter");
    const token = localStorage.getItem("recruiter");
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});


const baseQueryWithAuthHandling = async (args, api) => {
  const result = await baseQuery(args, api);

  if (result.error && result.error.status === 401) {
    cookies.remove("recruiter")
    localStorage.removeItem("recruiter")
    window.location.href = '/recruiter/login';
  }
  return result;
};


export const RecruiterApi = createApi({
  reducerPath: 'recruiter',
  baseQuery: baseQueryWithAuthHandling,
  endpoints: (builder) => ({
    sendMailVerify: builder.mutation({
      query: (payload) => ({
        url: '/recruiter-verify-mail',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['recruiter'],
    }),  
    recruiterRegister: builder.mutation({
      query: (payload) => ({
        url: '/recruiter-register',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['recruiter'],
    }),  
    recruiterLogin: builder.mutation({
      query: (payload) => ({
        url: '/recruiter/login',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['recruiter'],
    }),
    //recruiterTeam
    recruiterCreateTeam: builder.mutation({
      query: (payload) => ({
        url: '/recruiter/create-team',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['recruiter'],
    }),
    recruiterUpdateTeam: builder.mutation({
      query: ({formdata,id}) => {
        return { 
          url: `/recruiter/update-team/${id}`,
          method: 'PUT',
          body: formdata,
        }
      },
      invalidatesTags: ['recruiter'],
    }),
    getrecruiterTeam: builder.query({
      query: () => `/recruiter/get-teams`,
      providesTags: ['recruiter'],
    }),
    getSingleTeam: builder.query({
      query: (id) => `/recruiter/get-single-team/${id}`,
      providesTags: ['recruiter'],
    }),
    deleterecruiterTeam:builder.mutation({
      query:(id)=>({
        url:`/recruiter/delete-team/${id}`,
        method:'DELETE'
      }),
      invalidatesTags: ['recruiter'],
    }),
    //recruiterTeam
    getBillingDetails: builder.query({
      query: () => `/recruiter/billing-report`,
      providesTags: ['recruiter'],
    }),
    recruiterPostPlan: builder.mutation({
      query: (payload) => ({
        url: '/recruiter/get-post-plan',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['recruiter'],
    }),
    selectCandidate: builder.mutation({
      query: (payload) => ({
        url: `/recruiter/select-candidate`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['recruiter'],
    }),
    recruiterResumePlan: builder.mutation({
      query: (payload) => ({
        url: '/recruiter/get-resume-plan',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['recruiter'],
    }),
    recruiterUpdate: builder.mutation({
      query: (payload) => ({
        url: '/recruiter/update-details',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['recruiter'],
    }),  
    forgotPassword: builder.mutation({
      query: (payload) => ({
        url: '/recruiter/forgot-password',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['location'],
    }),
    resetPassword: builder.mutation({
      query: (payload) =>{
        return {
          url: `/recruiter/password/reset/${payload?.token}`,
        method: 'POST',
        body: payload,
        }
      },
      invalidatesTags: ['location'],
    }),
    recruiterUpdatePassword: builder.mutation({
      query: (payload) => ({
        url: '/recruiter/change-password',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['recruiter'],
    }),  
    recruiterLogout: builder.mutation({
      query: (payload) => ({
        url: '/recruiter/logout',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['recruiter'],
    }),  
    getDetails: builder.query({
      query: () => `/recruiter/details`,
      providesTags: ['recruiter'],
    }),
    // job
    createJob: builder.mutation({
      query: (payload) => ({
        url: '/recruiter/create-post',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['recruiter'],
    }),
    sendMail: builder.mutation({
      query: (id) => ({
        url: `/recruiter/send-mail-job/${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['recruiter'],
    }),
    UpdateJob: builder.mutation({
      query: ({formdata,id}) => ({
        url: `/recruiter/update-job?id=${id}`,
        method: 'PUT',
        body: formdata,
      }),
      invalidatesTags: ['recruiter'],
    }),
    deleteJob: builder.mutation({
      query: (id) => ({
        url: `/recruiter/delete-post/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['recruiter'],
    }),
    getJobs: builder.query({
      query: ({filter,page,rowsPerPage}) => `/recruiter/get-all-post?page=${page}&limit=${rowsPerPage}&filter=${filter}`,
      providesTags: ['recruiter'],
    }),
    getSingleJobs: builder.query({
      query: (id) => `/recruiter/get-single-post?id=${id}`,
      providesTags: ['recruiter'],
    }),
    getPlans: builder.query({
      query: () => `/recruiter/fetch-subscription`,
      providesTags: ['recruiter'],
    }),
    getSingleCandidate: builder.query({
      query: (id) => `/recruiter/get-single-candidate?id=${id}`,
      providesTags: ['recruiter'],
    }),
    getselectedAlljobs: builder.query({
      query: ({page,selectDepartment,selectInd,salary,exp,location}) => `/recruiter/get-select-candidate?department=${selectDepartment}&industry=${selectInd}&salary=${salary}&experience=${exp}&location=${location}&page=${page}`,
      providesTags: ['recruiter'],
    }),
    getDashboard: builder.query({
      query: (id) => `/recruiter/dashboard`,
      providesTags: ['recruiter'],
    }),
    getNotification: builder.query({
      query: ({search,page}) => `/recruiter/notification?search=${search}&page=${page}`,
      providesTags: ['recruiter'],
    }),
    getselectedCandidateFromjobs: builder.query({
      query: () => `/recruiter/get-selected-candidate`,
      providesTags: ['recruiter'],
    }),
    getMessages: builder.query({
      query: () => `/recruiter/messages`,
      refetch: (arg, { dispatch, getState }) => ({
        interval: 1000, // refetch every 1 second
      }),
    }),
    createMessage: builder.mutation({
      query: (formdata) => ({
        url: `/recruiter/create-message`,
        method: 'POST',
        body:formdata
      }),
      invalidatesTags: ['recruiter'],
    }),
    deleteNotification:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/recruiter/delete-notification',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['recruiter'],
    }),
    downloadResume:builder.mutation({
      query: () => { 
        return {
          url: '/recruiter/resume-download',
          method:'POST',  
        };
      },
        invalidatesTags: ['recruiter'],
    }),
    // job
  }),
})


export const { useRecruiterRegisterMutation,
  useRecruiterPostPlanMutation,
  useRecruiterResumePlanMutation,
  useRecruiterUpdateMutation,
  useRecruiterLogoutMutation ,
   useGetDetailsQuery,
  useSendMailVerifyMutation,
  useRecruiterLoginMutation,
useRecruiterCreateTeamMutation,
useGetrecruiterTeamQuery,
useDeleterecruiterTeamMutation,
useGetSingleTeamQuery,
useRecruiterUpdateTeamMutation,
useRecruiterUpdatePasswordMutation,
useGetBillingDetailsQuery,
useCreateJobMutation,
useGetJobsQuery,
useDeleteJobMutation,
useGetSingleJobsQuery,
useUpdateJobMutation,
useSelectCandidateMutation,
useGetselectedAlljobsQuery,
useGetSingleCandidateQuery,
useGetDashboardQuery,
useGetNotificationQuery,
useDeleteNotificationMutation,
useForgotPasswordMutation,
useResetPasswordMutation,
useSendMailMutation,
useDownloadResumeMutation,
useGetselectedCandidateFromjobsQuery,
useGetMessagesQuery,
useCreateMessageMutation,
useGetPlansQuery
} = RecruiterApi;